import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="content-padding">
        <h1 className="mtn">Error 404</h1>
        <p>Page not found. You're seeing this because:</p>
        <ul>
            <li>There's a typo in the address</li>
            <li>This page has been moved to a new address</li>
            <li>This page has been deleted</li>
            <li>Something is broken</li>
        </ul>
    </div>
  </Layout>
)

export default NotFoundPage
