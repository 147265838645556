import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => (

<nav className="navigation">
  <div className="nav-link-container">
    <ul>
      <li>
        <Link to="/" className="home-link">
        {siteTitle}
        </Link>
      </li>
      <li>
        <Link to="/about/" className="about-link">About</Link>
      </li>
    </ul>
  </div>
</nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
